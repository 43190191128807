import React from 'react'
import Logo from '../Assets/Logo.svg'
import {BsTwitter} from 'react-icons/bs'
import {BsYoutube} from 'react-icons/bs'
import {FaFacebook} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <div className="footer-wrapper">
        <div className="footer-section-one" >
            <div className="footer-logo-container" >
                <img src={Logo} alt="" />
            </div>
            <div className="footer-icons" >
            <a href="https://www.youtube.com/your-youtube-page">
             <BsYoutube />
             </a>
            <a href="https://www.facebook.com/your-facebook-page">
             <FaFacebook />
            </a>
            <a href="https://www.twitter.com/your-twitter-page">
             <BsTwitter />
            </a>
            <a href="https://www.instagram.com/officialaktioncast/">
            <BsInstagram />
            </a>
            </div>
        </div>
        <div className="footer-section-two" >
            <span>Quality</span>
            <br />
            <span>Help</span>
            <span>Share</span>
            <span>Careers</span>
            <span>Testimonials</span>
            <span>Work</span>
        </div>
        <div className="footer-section-columns">
            <span></span>
        </div>
        <div className="footer-section-columns">
            <span>Established Date : 1st February 2020. </span>
            <span>Location: Jamuhuri Showground East Stand </span>
            <span> Website Url: https://aktioncast.com </span>
            <span>Contact info: +254736207134</span>
        </div>
    </div>
  )
}

export default Footer