import React from 'react'
import PickMeals from '../Assets/pick-meals-image.png'
import ChooseMeals from '../Assets/choose-image.png'
import DeliveryMeals from '../Assets/delivery-image.jpeg'
import EditorImage from '../Assets/editor-image.png'
import EditImage from '../Assets/edit-image.jpg'
import Map from '../App'

const Work = () => {
  const workInfoData = [
    {
        image: PickMeals,
        title: "Team Leader",
        text: 'Robert Maoga',
    },
    {
        image: ChooseMeals,
        title: "Producer",
        text: 'Kenedy Kechula',
    },
    {
        image: DeliveryMeals,
        title: "Head of Digital",
        text: 'Brian Kubasu',
    },
    {
        image: EditorImage,
        title: "Lead Designer",
        text: 'Brian Mwachofi',
    },
    {
        image: EditImage,
        title: "Director of Photography",
        text: 'Cyril Walucho',
    },
  ]
 return( <div className="work-section-wrapper">
    <div className="work-section-top">
        <p className="primary-heading">
            Team Members
        </p>
    </div>
    <div className="work-section-bottom" >
        {workInfoData.map((data) => (
             <div className="work-section-info" >
             <div className="info-boxes-img-container">
                 <img src={data.image} alt="" />
             </div>
             <h2>{data.title}</h2>
             <p>{data.text}</p>
         </div>
        ))}
    </div>
 </div>
 )
}

export default Work