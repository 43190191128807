import React from 'react'
import Navbar from './Navbar'
import { FiArrowRight } from 'react-icons/fi'

const Home = () => {
  return (<div className="home-container">
    <Navbar />
    <div className="home-banner-container" >
      <div className="home-text-section" >
        <h1 className="primary-heading" >
    Aktioncast
        </h1>
        <p className="primary-heading">
        We specialize in branding, photography, videography, social media management and digital marketing.
         </p>
      </div>
    </div>
  </div>)
}

export default Home