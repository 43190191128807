import React from 'react'
import {AiFillStar} from 'react-icons/ai'

const Testimonials = () => {
  return <div className="work-section-wrapper" >
        <div className="work-section-top"> 
            <p className="primary-subheading">Our Services</p>
        </div>
        <div className="testimonial-section-bottom">
          <p>
            A clever strategy serves as the foundation and culmination of any marketing campaign. 
            The same, in our opinion, applies to social media. 
            For your brand, our team is committed to developing and implementing distinctive initiatives. 
            We are aware that social media allows brands the ability to connect with their desired customers through precise targeting, pertinent content, and deep engagement. 
            However, it might be challenging to utilize social media to its greatest potential. 
            Many platforms that are always changing.
          </p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>Social Media Management</h2>
        </div>
        <div className="testimonial-section-bottom">
          <p>
            We are a digital, marketing, public relations, and advertising agency with a broad range of integrated services. 
            We are a top group of alpha-experimentalists, storytellers, and businesspeople. 
            We combine art and science to make products popular. 
            We have stayed committed to our objective, which is to create enduring connections between people and brands. 
            We invest in people and provide them with the platform to succeed because we know that great talent will produce excellent work. 
            It serves as our core value, our commitment, and our company strategy.
          </p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>Digital Marketing</h2>
        </div>
        <div className="testimonial-section-bottom">
          <p>
          We take great satisfaction in our ability to develop outstanding and enduring brand identities as a top branding firm. 
          Our highly qualified experts are dedicated to providing top-notch services to companies in various industries. 
          Through thorough consultations, we learn about the goals and aspirations of our clients, providing the groundwork for a solid brand strategy. Our design process is guided by thorough market research, which produces eye-catching logos, illustrations, and color schemes that capture the spirit of each company. 
          Our brand standards guarantee seamless application across all touchpoints while maintaining consistency. 
          </p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2> Branding</h2>
        </div>
        <div className="testimonial-section-bottom">
          <p>
          We provide top-notch professional photography services for weddings, portraits, and business assignments. 
          Our team of talented photographers is characterized by a sharp eye for detail and a passion for getting the perfect shot. 
          We capture the happiness, love, and natural moments at weddings that make the occasion genuinely special. 
          To capture the individuality and soul of the subject, we produce breathtaking and expressive portraits. 
          Additionally, by presenting their goods and services in the most enticing and eye-catching way possible, businesses can enhance the market perception of their brand.</p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2> Photography</h2>
        </div>
        <div className="testimonial-section-bottom">
          <p>
          Our company offers various videography services to accommodate weddings, celebrations, and business endeavors. 
          Our skilled videographers record moments with accuracy and beauty, focusing on professionalism and creativity. For weddings, we produce lovely and moving wedding films that capture the couple's special day. 
          We ensure that every occasion's spirit is skillfully captured through event videography. 
          Additionally, we create captivating videos for commercial projects that highlight goods, services, and brand histories and leave an impression on the audience.
          </p>
          <div className="testimonials-stars-container">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2> Videography</h2>
        </div>
  </div>
}

export default Testimonials