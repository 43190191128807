import React from 'react'
import { BsFillPlayCircleFill } from 'react-icons/bs'

const About = () => {
  return (
    <div className="about-section-container">
        <div clasName="about-section-text-container">
            <p className="primary-subheading">About Us</p>
            <h1 className="primary-heading">
           We are Aktioncast
            </h1>
            <p className="primary-text">
            We are dedicated to helping broadcasters and content providers avail content to viewers through the audiences’ preferred platform, and for more people to have access to the content. 
            With a great team, industry knowledge and experience, we provide solutions built for our client’s needs, allowing our media partners to focus on their audience and consumer engagement.
            </p>
            <h1 className="primary-heading">
            Mission
            </h1>
            <p className="primary-text">
            To revolutionalize content production.
            </p>
            <h1 className="primary-heading">
            Vision
            </h1>
            <p className="primary-text">
            To be leading content producer in the world.
            </p>
            <h1 className="primary-heading">
            Values
            </h1>
            <p className="primary-text">
            Creativity. Honesty. Innovation
            </p>
        </div>
    </div>
  )
}

export default About